// AUTO
.header-btn {
  .header__btn-dots span {
    animation-delay: 0.15s;
  }

  .header__btn-dots:after {
    animation-delay: 0.3s;
  }

  .header__btn-dots:before,
  .header__btn-dots:after {
    content: '';
  }

  .header__btn-dots:before,
  .header__btn-dots:after,
  .header__btn-dots span {
    position: relative;
    display: inline-block;
    vertical-align: middle;

    width: 4px;
    height: 4px;

    background: #333333;
    border-radius: 50%;
    opacity: 0.5;

    animation-name: dotsFlicker;
    animation-duration: 1.75s;
    animation-iteration-count: infinite;
    animation-play-state: running;
  }

  &:hover {

    .header__btn-dots:before,
    .header__btn-dots:after,
    .header__btn-dots span {
      background: #050505;
      ;
    }
  }

  @-webkit-keyframes dotsFlicker {
    5% {
      opacity: 0.2;
    }

    10% {
      top: -1px;
    }

    15% {
      opacity: 0;
    }

    25% {
      opacity: 1;
    }

    40% {
      top: 0;
    }

    50% {
      opacity: 0.5;
    }
  }

  @keyframes dotsFlicker {
    5% {
      opacity: 0.2;
    }

    10% {
      top: -1px;
    }

    15% {
      opacity: 0;
    }

    25% {
      opacity: 1;
    }

    40% {
      top: 0;
    }

    50% {
      opacity: 0.5;
    }
  }
}

// Full page
.custom-hidden,
.custom__hidden-slider {
  opacity: 0;
  filter: blur(0.5px);
}

.custom-hidden {
  transition: all 0.8s;
}

.custom__hidden-slider {
  transition: all 1s;
  transform: translateX(-10%);
}

.item:nth-child(2) {
  transition-delay: 200ms;
}

.item:nth-child(3) {
  transition-delay: 400ms;
}

.custom-show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
}

// Banner
.type-write .wrap {
  padding-right: 4px;
  border-right: .1em solid orange;
  animation: blink-caret .75s step-end infinite;
}

@keyframes blink-caret {

  from,
  to {
    border-color: transparent;
    border-right: .1em solid orange;
  }

  50% {
    border: orange;
  }
}

// CUSTOM
.link:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 1.4px;
  background-color: #F3F6FE;
  transition: .3s;
}

.link:hover:before {
  width: 100%;
}

/*****/

// xs
@media (min-width: 375px) {}

// sm
@media (min-width: 576px) {}

// md
@media (min-width: 768px) {}

// lg
@media (min-width: 1024px) {}

// xl
@media (min-width: 1280px) {

  // Full page
  .custom__hidden-slider {
    transition: all 1s;
    transform: translateX(-100%);
  }

  .custom-show {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
  }
}

// 2xl
@media (min-width: 1536px) {}

// 3xl
@media (min-width: 1650px) {}

/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  padding: 5px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #25b09b;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {to{transform: rotate(1turn)}}