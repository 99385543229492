/* Author: Chien Tran
 * - Style mobile first
 * - Order by structure
 * - Folder name capitalized
*/

$primary-color: linear-gradient(86.95deg, #0c66e4 0%, #07387e 100%);
$footer-primary-color: #09326c;
$primary-color-hover: linear-gradient(
  86.95deg,
  #255682 2.58%,
  #2683b7 55.41%,
  #26b3ae 105.04%
);

$text-color: -webkit-linear-gradient(
  86.95deg,
  #26b3ae 2.58%,
  #2683b7 55.41%,
  #255682 105.04%
);

// COMPONENTS
// home
// _SolutionSlider
.solution__slider-item {
  $full: 100%;
  $overlay: linear-gradient(
    180deg,
    rgba(201, 201, 201, 0.068) 0%,
    rgba(58, 58, 58, 0.445) 96.6%
  );
  position: relative;
  border-radius: 1rem;

  &:hover {
    .item-img {
      transform: scale(0.98);
      height: $full;
      object-fit: cover;
    }

    .item-overlay {
      opacity: 1;
      transform: scale(0.98);
      transition: all 0.6s;
    }
  }

  .item-img {
    width: $full;
    height: $full;
    object-fit: cover;
    transition: all 0.6s;
  }

  .item-overlay {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    background: $overlay;
    width: $full;
    height: $full;

    .item-title {
      opacity: 0;
      position: absolute;
      bottom: 20px;
      left: 50%;
      transition: all 0.6s;
    }

    &:hover {
      .item-title {
        display: block;
        opacity: 1;
        transform: translateX(-50%);
      }
    }
  }
}

// layout
// _Header
.lock {
  overflow: hidden;
}

.nav__mobile {
  z-index: 999;

  .nav__mobile-in,
  .overlay-in {
    top: 0;
    right: 0;
    bottom: 0;
    animation: slideIn 0.8s;
  }

  @keyframes slideIn {
    from {
      right: -100%;
    }

    to {
      right: 0;
    }
  }

  .overlay-in {
    left: 0;
    animation: fadeIn linear 0.8s;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 0.3;
    }
  }

  .nav__mobile-out,
  .overlay-out {
    top: 0;
    right: -100%;
    bottom: 0;
    animation: slideOut alternate 0.8s;
  }

  @keyframes slideOut {
    from {
      right: -20%;
    }

    to {
      right: -100%;
    }
  }

  .overlay-out {
    animation: fadeOut linear 0.8s;
  }

  @keyframes fadeOut {
    from {
      opacity: 0.3;
    }

    to {
      opacity: 0;
    }
  }

  .active {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    background: $primary-color;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}

/*****/

// LAYOUT
// _Layout
.bgr__home-page {
  background: $primary-color;
}

.bgr__sub-page {
  height: 340px;
  background-color: #f3f6fe;
  // background-image: url('../images/bgr-sub-page-md.png');
  background: $primary-color;
  background-repeat: no-repeat;
  background-origin: border-box;
  background-size: 100% 100%;
}

.header-btn {
  min-width: 148px;
  height: 50px;

  background: #fff;
  box-shadow: 0px 16px 65px rgba(34, 80, 179, 0.25);
  border-radius: 46px;
}

footer {
  background: $footer-primary-color;
}

/*****/

// PAGES
// sub
// _About
.about {
  .history .years {
    .title-active {
      color: #0c66e4;
    }

    img,
    h4 {
      transition: all 0.5s;
    }

    .img-active {
      filter: drop-shadow(0 6px 5px #9ebde9);
    }

    h4:hover {
      background: #0c66e4;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    img:hover,
    .img-default {
      filter: drop-shadow(0 6px 5px #9ebde9);
    }
  }

  .history .content .current-year,
  .history .content .content-highlight {
    background: $text-color;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

// _Contact
.contact {
  .contact-form .contact_form-btn {
    background: $primary-color;
  }
  .contact_form-btn:hover {
    background: $primary-color-hover;
  }
}

// _Service
.service {
  $gradient-service: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    linear-gradient(86.95deg, #255682 2.58%, #2683b7 55.41%, #26b3ae 105.04%);

  .service-list .service__list-content {
    background: $gradient-service;
  }
}

// _Home
.home {
  .about {
    .about__top-btn {
      background: $primary-color;

      &:hover {
        background: $primary-color-hover;
      }
    }
  }

  .service {
    .box-scroll {
      overflow-x: auto;

      li a {
        background: $primary-color;

        &:hover {
          background: $primary-color-hover;
        }
      }
    }
  }

  .solution {
    .prev-arrow,
    .next-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(50%);

      display: flex;
      align-items: center;
      justify-content: center;

      width: 28px;
      height: 28px;
      border-radius: 50%;
      background: black;
      cursor: pointer;

      &:hover {
        scale: 1.1;
      }

      img {
        width: 10px;
        height: 10px;
      }
    }

    .next-arrow {
      img {
        transform: rotate(180deg);
      }
    }
  }

  .contact {
    .contact_form-btn {
      background: $primary-color;
    }

    .contact_form-btn:hover {
      background: $primary-color-hover;
    }
  }
}

/*****/

// xs
@media (min-width: 375px) {
}

// sm
@media (min-width: 576px) {
}

// md
@media (min-width: 768px) {
  .bgr__sub-page {
    // background-image: url('../images/bgr-sub-page.png');
  }
}

// lg
@media (min-width: 1024px) {
  .bgr__sub-page {
    height: 480px;
    // background-image: url('../images/bgr-sub-page-xl.png');
  }
}

// xl
@media (min-width: 1280px) {
}

// 2xl
@media (min-width: 1536px) {
}

// 3xl
@media (min-width: 1650px) {
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
  padding: 4px;
}

.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: #1f2a37 !important;
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
  bottom: -5px !important;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 10px !important;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (min-width: 2048px) {
  .container {
    max-width: 1650px !important;
  }
}
//
.container-history {
  width: 100%;
}

.image-baner {
  display: none;
}

@media (min-width: 640px) {
  .container-history {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container-history {
    max-width: 768px;
  }
  .image-baner {
    display: inline;
  }
}

@media (min-width: 1024px) {
  .container-history {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container-history {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container-history {
    max-width: 1536px;
  }
}

@media (min-width: 2048px) {
  .container-history {
    max-width: 1650px !important;
  }
}
