// TOAST
:root {
  --toastify-color-success: linear-gradient(
    86.95deg,
    #26b3ae 2.58%,
    #2683b7 55.41%,
    #255682 105.04%
  ) !important;
  --toastify-icon-color-success: #2683b7 !important;
  --toastify-font-family: DM Sans !important;
}

$primary-color: linear-gradient(
  86.95deg,
  #26b3ae 2.58%,
  #2683b7 55.41%,
  #255682 105.04%
);
$primary-color-hover: linear-gradient(
  86.95deg,
  #255682 2.58%,
  #2683b7 55.41%,
  #26b3ae 105.04%
);

.banner-slider {
  .slick-list {
    height: 100%;
    .slick-track {
      height: 100%;
      .slick-slide {
        height: 100%;
        div {
          height: 100%;
        }
      }
    }
  }

  .content-baner .slick-dots {
    display: flex !important;
    align-items: center;
    justify-content: center;
    column-gap: 0.8rem;
    bottom: 12px;

    li {
      margin: 0;
      width: 0.4rem;
      height: 0.4rem;
      border-radius: 50%;
      opacity: 0.4;
      background: white;
    }

    .slick-active {
      width: 1rem;
      border-radius: 0.4rem;
      opacity: 1;
      animation: show 0.3s;
    }

    @keyframes show {
      from {
        width: 0.4rem;
        opacity: 0.4;
      }

      to {
        width: 1rem;
        opacity: 1;
      }
    }

    li button:before {
      content: '';
    }
  }
}

.slider_second-custom {
  .slick-list .slick-track {
    display: flex;
    align-items: center;
  }

  .prev-arrow,
  .next-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    display: flex;
    align-items: center;
    justify-content: center;

    width: 26px;
    height: 26px;
    border-radius: 35%;
    background: $primary-color;
    cursor: pointer;

    img {
      width: 10px;
      height: 10px;
      opacity: 0.5;
    }
  }

  .next-arrow {
    img {
      transform: rotate(180deg);
    }
  }

  .slick-center div div img {
    height: 68px !important;
  }
}

.place-card {
  display: none !important;
}

@media (min-width: 576px) {
  .slider_second-custom {
    .slick-center div div img {
      height: 100px !important;
    }
  }
}

@media (min-width: 1024px) {
  .slider_second-custom {
    .slick-center div div img {
      height: 140px !important;
    }
  }
}

.generator {
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
